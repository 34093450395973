const waitForDocumentReady = async () =>
  new Promise<void>((resolve) => {
    /* istanbul ignore else */
    if ((document.readyState === 'interactive' && Boolean(document.body)) || document.readyState === 'complete') {
      resolve();

      return;
    }

    document.addEventListener('DOMContentLoaded', () => {
      resolve();
    });
  });

const documentReady = waitForDocumentReady();

export default documentReady;
