/*
  The original version of this code comes from https://github.com/ealmansi/set-interval-async I did not use it becuase I don't have the time to make TS work with ecmascript modules.
  Once Hulk supports ecmascript modules we should use the set-interval-async as a dependency instead of this version of the module.
*/

import {clearIntervalAsync} from './clearIntervalAsync';
import {SetIntervalAsyncHandler} from './types';
import {SetIntervalAsyncTimer} from './setIntervalAsyncTimer';

export {clearIntervalAsync};
export type {SetIntervalAsyncHandler};
export type {SetIntervalAsyncTimer};

export const setIntervalAsync = <HandlerArgs extends unknown[]>(
  handler: SetIntervalAsyncHandler<HandlerArgs>,
  intervalMs: number,
  ...handlerArgs: HandlerArgs
): SetIntervalAsyncTimer<HandlerArgs> => {
  if (!(typeof handler === 'function')) {
    throw new TypeError('First argument is not a function');
  }
  if (!(typeof intervalMs === 'number')) {
    throw new TypeError('Second argument is not a number');
  }

  return SetIntervalAsyncTimer.startTimer<HandlerArgs>(handler, intervalMs, ...handlerArgs);
};
