class SimpleStorage<T> {
  readonly name: string;
  readonly storage: Storage;

  constructor(name: string, storageMechanism: Storage = localStorage) {
    this.name = name;
    this.storage = storageMechanism;
  }

  get(): T | null {
    try {
      const rawData = this.storage.getItem(this.name);

      if (rawData !== null) {
        return JSON.parse(rawData);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    return null;
  }

  set(data: T) {
    try {
      this.storage.setItem(this.name, JSON.stringify(data));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  clear() {
    try {
      this.storage.removeItem(this.name);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}

export default SimpleStorage;
