import {SavedPullCampaign} from '@mol-fe/mol-fe-web-push-types';
import urlJoin from 'url-join';
import fetch from '../helpers/fetchWithRetry';

export const fetchPullCampaigns = async (baseEndpoint: string, apiKey?: string): Promise<SavedPullCampaign[]> => {
  const requestInit: RequestInit = {
    mode: 'cors'
  };

  if (apiKey) {
    requestInit.headers = {Authorization: `ApiKey ${apiKey}`};
  }

  const response = await fetch(urlJoin(baseEndpoint, 'pull-campaign'), requestInit, true);

  return response.json() || [];
};
