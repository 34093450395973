import {AnalyticsEvent} from '@mol-fe/mol-fe-web-push-types';
import urlJoin from 'url-join';
import fetch from './fetchWithRetry';

const trackEvent = async (
  baseEndpoint: string,
  event: Omit<AnalyticsEvent, 'creationTimestamp' | 'epoch'>,
  apiKey?: string
) => {
  const {type, action} = event;
  const trackEndpoint = urlJoin(baseEndpoint, 'analytics/track', type, action);
  const requestInit: RequestInit = {
    body: JSON.stringify(event),
    headers: {
      'Content-type': 'application/json'
    },
    method: 'post',
    mode: 'cors'
  };

  if (apiKey) {
    requestInit.headers = {...requestInit.headers, Authorization: `ApiKey ${apiKey}`};
  }

  return fetch(trackEndpoint, requestInit);
};

export default trackEvent;
