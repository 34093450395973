import {RegisterOpts} from '../types';

const isBadString = (value: unknown, maxLength = 0) =>
  typeof value !== 'string' || value.length === 0 || (maxLength && maxLength < value.length);

const isBadObject = (value: unknown, properties?: string[]) => {
  const type = typeof value;

  if (value !== null && type === 'object') {
    const keys = Object.keys(value as object);
    const hasProps =
      properties && properties.map((prop) => keys.includes(prop)).filter(Boolean).length === properties.length;

    return !keys.length || !hasProps;
  }

  return true;
};

const isBadBoolean = (value: unknown) => typeof value !== 'boolean';

const isBadStringArray = (value: unknown) =>
  !Array.isArray(value) || (value.length && value.map(isBadString).every(Boolean));

const requiredMetadataProps = ['organisation', 'platform', 'profilingAllowed', 'userAgent'];

export default (opts: Partial<RegisterOpts>): RegisterOpts => {
  // required props
  if (isBadObject(opts.metadata, requiredMetadataProps)) {
    throw new Error(
      `metadata is required and must be object containing the following required fields ${requiredMetadataProps.join(
        ', '
      )}.`
    );
  }

  if (isBadString(opts.metadata?.organisation)) {
    throw new Error('metadata.organisation is required and must be a non empty string.');
  }

  if (isBadString(opts.metadata?.platform)) {
    throw new Error('metadata.platform is required and must be a non empty string.');
  }

  if (isBadBoolean(opts.metadata?.profilingAllowed)) {
    throw new Error('metadata.profilingAllowed is required and must be a boolean.');
  }

  if (isBadString(opts.metadata?.userAgent)) {
    throw new Error('metadata.userAgent is required and must be a non empty string.');
  }

  // optional props
  if (opts.swUrl !== undefined && isBadString(opts.swUrl)) {
    throw new Error('swUrl is optional but must be non empty string when provided.');
  }

  if (opts.metadata?.visitorIds !== undefined && isBadStringArray(opts.metadata?.visitorIds)) {
    throw new Error('metadata.visitorIds is optional but must be a non empty string array.');
  }

  if (opts.metadata?.crossOrgUserIds !== undefined && isBadStringArray(opts.metadata?.crossOrgUserIds)) {
    throw new Error('metadata.crossOrgUserIds is optional but must be a non empty string array.');
  }

  if (opts.metadata?.keywords !== undefined && isBadStringArray(opts.metadata?.keywords)) {
    throw new Error('metadata.keywords is optional but must be a non empty string array when provided.');
  }

  if (opts.metadata?.channels !== undefined && isBadStringArray(opts.metadata?.channels)) {
    throw new Error('metadata.channels is optional but must be a non empty string array when provided.');
  }

  if (opts.metadata?.subChannels !== undefined && isBadStringArray(opts.metadata?.subChannels)) {
    throw new Error('metadata.subChannels is optional but must be a non empty string array when provided.');
  }

  return opts as RegisterOpts;
};
