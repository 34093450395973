import urlJoin from 'url-join';
import Emitter from './helpers/Emitter';
import {CanRenderPullNotification, RegisterOpts, RenderPullNotification} from './types';
import fetchApplicationServerKey from './helpers/fetchApplicationServerKey';
import registrationInit from './registration/init';
import registrationShowPrompt, {RenderPrePrompt} from './registration/showPrompt';
import getBaseEndpoint from './helpers/getBaseEndpoint';
import {isWebPushSupported as isWebPushSupportedFn} from './helpers/pushSubscription';
import validateRegisterOpts from './helpers/validateRegisterOpts';
import {init as initNotificationPulling} from './pullCampaign/init';
import {getCrossOrgUserId} from './helpers/getCrossOrgUserId';

export type EventTypes =
  | 'error'
  | 'prompt_pre_prompt_click'
  | 'prompt_pre_prompt_impression'
  | 'prompt_click'
  | 'prompt_impression'
  | 'prompt_discarded'
  | 'notification_click'
  | 'pull_notification_impression'
  | 'pull_notification_click';

export class Register extends Emitter<EventTypes> {
  static isWebPushSupported() {
    return isWebPushSupportedFn();
  }

  private readonly options: RegisterOpts;
  private readonly baseEndpoint: string;
  private readonly subscriptionEndpoint: string;
  private appServerKey: {parsed: Uint8Array; raw: string} | null = null;
  private registration: ServiceWorkerRegistration | null = null;
  private crossOrguserId: string | undefined;

  constructor(options: Partial<RegisterOpts>) {
    super();

    const baseEndpoint = options.environment ? getBaseEndpoint(options.environment) : options.baseEndpoint || '';

    this.options = validateRegisterOpts({...options});
    this.baseEndpoint = urlJoin(baseEndpoint, '/v1/organisation', this.options.metadata.organisation);
    this.subscriptionEndpoint = urlJoin(this.baseEndpoint, 'subscription');
  }

  private async getAppServerKey(force = false) {
    if (this.appServerKey === null || force) {
      this.appServerKey = await fetchApplicationServerKey(
        urlJoin(this.subscriptionEndpoint, 'publicKey'),
        this.options.apiKey
      );
    }

    return this.appServerKey;
  }

  private async getCrossOrgUserId() {
    if (!this.crossOrguserId) {
      this.crossOrguserId = await getCrossOrgUserId(this.options.metadata, (name, args) => this.emit(name, args));
    }

    return this.crossOrguserId;
  }

  async init() {
    const {parsed, raw} = (await this.getAppServerKey()) || {};

    this.registration = await registrationInit({
      ...this.options,
      appServerKey: parsed,
      baseEndpoint: this.baseEndpoint,
      crossOrgUserId: await this.getCrossOrgUserId(),
      emit: (name, args) => this.emit(name, args),
      rawAppServerKey: raw,
      subscriptionEndpoint: this.subscriptionEndpoint
    });

    return this.registration;
  }

  async initPullNotifications({
    renderPullNotification,
    canRender
  }: {
    renderPullNotification: RenderPullNotification;
    canRender?: CanRenderPullNotification;
  }) {
    if (typeof renderPullNotification !== 'function') {
      throw new TypeError('Missing required option `renderPullNotification` function');
    }

    if (canRender && typeof canRender !== 'function') {
      throw new TypeError('Optional `canRender` option must be a function');
    }

    await initNotificationPulling({
      apiKey: this.options.apiKey,
      baseEndpoint: this.baseEndpoint,
      canRender,
      crossOrgUserId: await this.getCrossOrgUserId(),
      emit: (name, args) => this.emit(name, args),
      metadata: {
        geo: 'ALL',
        ...this.options.metadata
      },
      renderPullNotification
    });

    return this;
  }

  async showPrompt(renderPrePrompt?: RenderPrePrompt) {
    const {parsed, raw} = (await this.getAppServerKey()) || {};

    if (this.registration === null) {
      throw new Error('Init must be called before attempting to call showPrompt');
    }

    return registrationShowPrompt(this.registration, {
      ...this.options,
      appServerKey: parsed,
      baseEndpoint: this.baseEndpoint,
      emit: (name, args) => this.emit(name, args),
      rawAppServerKey: raw,
      renderPrePrompt,
      subscriptionEndpoint: this.subscriptionEndpoint
    });
  }
}

export type RegisterEmit = InstanceType<typeof Register>['emit'];
export {RegisterOpts} from './types';
