import {ServiceWorkerInitMessage, NotificationEvent} from '@mol-fe/mol-fe-web-push-types';
import {PushSubscriptionMetadata, CompleteRegisterOpts} from '../types';
import documentReady from '../helpers/documentReady';
import {
  needsMigration,
  registerPushSubscription,
  replaceSubscription,
  subscribePushNotifications,
  updatePushSubscription,
  willExpireSoon
} from '../helpers/pushSubscription';
import {setStatus, getStatus} from '../helpers/subscriptionStatus';
import handleSubscriptionError from '../helpers/handleSubscriptionError';
import {setBaseEndpoint} from '../helpers/clientSubscriptionErrors';
import trackEvent from '../helpers/safeTrackEvent';
import {deleteOldClickRecords, markAsTracked, wasTrackedBefore} from '../helpers/pushNotificationClickStorage';

interface GetClickActionOpts {
  sharedCrossOrgUserId?: string | null;
  crossOrgUserId?: string;
  campaignId: string;
  subscriptionId: string;
}

const getClickAction = ({
  sharedCrossOrgUserId,
  crossOrgUserId,
  campaignId,
  subscriptionId
}: GetClickActionOpts): NotificationEvent['action'] => {
  if (!sharedCrossOrgUserId) {
    return 'click';
  }

  if (crossOrgUserId !== sharedCrossOrgUserId) {
    return 'click_page_share';
  }

  if (wasTrackedBefore(campaignId, subscriptionId)) {
    return 'click_page_refresh';
  }

  return 'click';
};

const pruneMacros = (macros: CompleteRegisterOpts['notificationMacros']) => {
  if (!macros || typeof macros !== 'object' || Object.keys(macros).length === 0) {
    return undefined;
  }

  const prunedMacros: CompleteRegisterOpts['notificationMacros'] = {
    impression: macros.impression?.filter((macro) => {
      return typeof macro === 'string' || (Array.isArray(macro) && typeof macro[0] === 'string');
    })
  };

  return prunedMacros;
};

export default async (options: CompleteRegisterOpts) => {
  const {
    apiKey,
    appServerKey,
    notificationMacros,
    emit,
    metadata,
    baseEndpoint,
    swUrl,
    subscriptionEndpoint,
    rawAppServerKey,
    crossOrgUserId
  } = options;
  const visitorId = Array.isArray(metadata.visitorIds) ? metadata.visitorIds[0] : undefined;
  const userAgent = metadata.userAgent;

  try {
    await documentReady;
    deleteOldClickRecords();
    const params = new URLSearchParams(window.location.search);
    const ito = params.get('ito');

    setBaseEndpoint(baseEndpoint);

    let subscriptionStatus = getStatus();
    const crossOrgUserIds = crossOrgUserId ? [crossOrgUserId] : undefined;

    metadata.crossOrgUserIds = crossOrgUserIds;

    if (ito === 'push-notification') {
      const campaignId = params.get('ci');
      const campaignRunId = params.get('cri') || '';
      const subscriptionId = params.get('si');
      const assetId = params.get('ai');
      const sharedCrossOrgUserId = params.get('xi');

      if (campaignId && subscriptionId) {
        const action = getClickAction({
          campaignId,
          crossOrgUserId,
          sharedCrossOrgUserId,
          subscriptionId
        });

        const notificationClickEvent: Omit<NotificationEvent, 'creationTimestamp' | 'epoch'> = {
          action,
          campaignId,
          campaignRunId,
          crossOrgUserId,
          organisation: metadata.organisation,
          platform: metadata.platform,
          subscriptionId,
          type: 'notification',
          userAgent,
          visitorId
        };

        if (assetId) {
          notificationClickEvent.assetId = assetId;
        }

        emit('notification_click', notificationClickEvent);

        await trackEvent(
          baseEndpoint,
          {
            ...notificationClickEvent,
            action: action === 'click' ? 'click_page_load' : action
          },
          apiKey
        );

        markAsTracked(campaignId, subscriptionId);
      }
    }

    if (swUrl) {
      try {
        await navigator.serviceWorker.register(swUrl, {scope: '/'});
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('ServiceWorker registration failed: ', error);
      }
    }

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();
    const migrated = !!subscription && needsMigration(subscription, appServerKey);

    subscriptionStatus = getStatus();
    const msgData: ServiceWorkerInitMessage = {
      apiKey,
      baseEndpoint,
      crossOrgUserId,
      navigatorPlatform: window.navigator.platform,
      notificationMacros: pruneMacros(notificationMacros),
      organisation: metadata.organisation,
      permission: Notification.permission,
      subscription: migrated ? null : subscription,
      type: 'web-push-sw-init-data',
      userAgent: metadata.userAgent,
      visitorId
    };

    /// @ts-ignore
    registration.active.postMessage(JSON.stringify(msgData));

    if (subscriptionStatus && subscriptionStatus.state === 'undecided' && Notification.permission === 'granted') {
      setStatus('register_pending', subscriptionStatus.metadata);

      subscriptionStatus = getStatus();
    }

    if (
      subscriptionStatus &&
      subscriptionStatus.state !== 'undecided' &&
      subscriptionStatus.metadata &&
      (subscriptionStatus.metadata.userAgent !== metadata.userAgent ||
        subscriptionStatus.metadata.profilingAllowed !== metadata.profilingAllowed ||
        (subscriptionStatus.metadata.visitorIds || []).join(',') !== (metadata.visitorIds || []).join(',') ||
        (subscriptionStatus.metadata.crossOrgUserIds || []).join(',') !== (crossOrgUserIds || []).join(',') ||
        ito === 'push-notification')
    ) {
      const newMeta: PushSubscriptionMetadata = {
        ...subscriptionStatus.metadata,
        crossOrgUserIds: Array.from(
          new Set([...(subscriptionStatus.metadata.crossOrgUserIds || []), ...(crossOrgUserIds || [])])
        ),
        keywords: Array.from(new Set([...(subscriptionStatus.metadata.keywords || []), ...(metadata.keywords || [])])),
        profilingAllowed: metadata.profilingAllowed,
        userAgent: metadata.userAgent,
        visitorIds: Array.from(
          new Set([...(subscriptionStatus.metadata.visitorIds || []), ...(metadata.visitorIds || [])])
        )
      };

      const newState = subscriptionStatus.state === 'ready' ? 'update_pending' : subscriptionStatus.state;

      setStatus(newState, newMeta);

      subscriptionStatus = getStatus();
    }

    if (subscription) {
      const newMeta = subscriptionStatus ? subscriptionStatus.metadata : metadata;

      if (migrated || willExpireSoon(subscription)) {
        await replaceSubscription({
          apiKey,
          appServerKey,
          metadata: {
            ...metadata,
            migrated,
            migratedSubscriptionEndpoint: migrated ? subscription.endpoint : undefined
          },
          registration,
          subscription,
          subscriptionEndpoint
        });
      } else if (subscriptionStatus && subscriptionStatus.state === 'register_pending') {
        await registerPushSubscription(subscriptionEndpoint, {metadata: newMeta, subscription}, apiKey);
      } else if ((subscriptionStatus && subscriptionStatus.state === 'update_pending') || !subscriptionStatus) {
        await updatePushSubscription(subscriptionEndpoint, {metadata: newMeta, subscription}, apiKey);
      }
      setStatus('ready', metadata);
    } else if (Notification.permission === 'granted') {
      const meta = subscriptionStatus ? subscriptionStatus.metadata : metadata;

      await subscribePushNotifications({
        apiKey,
        appServerKey,
        metadata: meta,
        registration,
        subscriptionEndpoint
      });

      setStatus('ready', meta);
    }

    return registration;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    handleSubscriptionError(error as Error, {emit, metadata, rawAppServerKey});
  }

  return null;
};
