import {PromptEvent} from '@mol-fe/mol-fe-web-push-types';
import {PushSubscriptionMetadata} from '../types';
import {firedPromptEventActions} from '../registration/showPrompt';
import equalUint8Array from './equalUint8Array';
import fetch from './fetchWithRetry';
import PushSubscriptionError from './PushSubscriptionError';

export const isWebPushSupported = () => 'serviceWorker' in navigator && 'PushManager' in window;

const msPerWeek = 7 * 24 * 60 * 60 * 1000;

export const needsMigration = (subscription: PushSubscription, appServerKey: Uint8Array) => {
  return (
    subscription.options.applicationServerKey instanceof ArrayBuffer &&
    !equalUint8Array(appServerKey, new Uint8Array(subscription.options.applicationServerKey, 0))
  );
};

export const willExpireSoon = (subscription: PushSubscription) =>
  // @ts-ignore
  !!subscription.expirationTime && subscription.expirationTime < Date.now() + msPerWeek;

export interface SubscribePushNotificationOpts {
  apiKey?: string;
  appServerKey: BufferSource | string | null;
  metadata: Partial<PushSubscriptionMetadata>;
  registration: ServiceWorkerRegistration;
  subscriptionEndpoint: string;
  firedPromptEvents?: Omit<PromptEvent, 'creationTimestamp' | 'epoch'>[];
}

export interface PushSubscriptionData {
  subscription: PushSubscription;
  metadata: Partial<PushSubscriptionMetadata>;
  firedPromptEvents?: Omit<PromptEvent, 'creationTimestamp' | 'epoch'>[];
}

const isValidPushSubscription = (subscription: PushSubscription): boolean => {
  let auth;
  let p256dh;

  try {
    auth = subscription.getKey('auth');
    p256dh = subscription.getKey('p256dh');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error(`Unable to get auth and p256dh keys, subscription ${JSON.stringify(subscription, null, 2)}`);

    return false;
  }

  return auth !== null && p256dh !== null;
};

export const registerPushSubscription = async (
  subscriptionEndpoint: string,
  {subscription, metadata}: PushSubscriptionData,
  apiKey?: string
) => {
  if (isValidPushSubscription(subscription)) {
    const body = {
      metadata: {
        ...metadata,
        browserLanguage: navigator.language,
        firedPromptEventActions,
        subscribedUrl: location.href
      },
      subscription
    };
    const requestInit: RequestInit = {
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    };

    if (apiKey) {
      requestInit.headers = {...requestInit.headers, Authorization: `ApiKey ${apiKey}`};
    }

    return fetch(subscriptionEndpoint, requestInit);
  }

  return undefined;
};

export const subscribePushNotifications = async ({
  apiKey,
  appServerKey,
  metadata,
  subscriptionEndpoint,
  registration
}: SubscribePushNotificationOpts) => {
  try {
    const subscription = await registration.pushManager.subscribe({
      applicationServerKey: appServerKey,
      userVisibleOnly: true
    });

    await registerPushSubscription(subscriptionEndpoint, {metadata, subscription}, apiKey);

    return subscription;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new PushSubscriptionError((error as Error).message, 'register', error as Error);
  }
};

export const requestPushNotificationPermission = async () =>
  new Promise<NotificationPermission>((resolve, reject) => {
    const response = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (response instanceof Promise) {
      response.then(resolve, reject);
    }
  });

export const updatePushSubscription = async (
  subscriptionEndpoint: string,
  {subscription, metadata}: PushSubscriptionData,
  apiKey?: string
) => {
  const body = {
    metadata: {
      ...metadata,
      browserLanguage: navigator.language,
      subscribedUrl: location.href
    },
    subscription
  };
  const requestInit: RequestInit = {
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json'
    },
    method: 'PATCH',
    mode: 'cors'
  };

  if (apiKey) {
    requestInit.headers = {...requestInit.headers, Authorization: `ApiKey ${apiKey}`};
  }

  try {
    await fetch(subscriptionEndpoint, requestInit);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new PushSubscriptionError((error as Error).message, 'update', error as Error);
  }
};

interface ReplaceSubscriptionOpts {
  apiKey?: string;
  appServerKey: Uint8Array;
  metadata: Partial<PushSubscriptionMetadata>;
  registration: ServiceWorkerRegistration;
  subscription: PushSubscription;
  subscriptionEndpoint: string;
}

export const replaceSubscription = async ({
  apiKey,
  appServerKey,
  metadata,
  registration,
  subscription,
  subscriptionEndpoint
}: ReplaceSubscriptionOpts) => {
  await subscription.unsubscribe();

  return subscribePushNotifications({
    apiKey,
    appServerKey,
    metadata,
    registration,
    subscriptionEndpoint
  });
};
