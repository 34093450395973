import {PushSubscriptionMetadata} from '../types';
import SimpleStorage from './SimpleStorage';

export type PushSubscriptionState = 'undecided' | 'register_pending' | 'update_pending' | 'ready';
export interface PushSubscriptionStatus {
  state: PushSubscriptionState;
  metadata: PushSubscriptionMetadata;
}

const storage = new SimpleStorage<PushSubscriptionStatus>('MOL-FE-WEB-PUSH_SUBSCRIPTION_STATUS');

export const setStatus = (state: PushSubscriptionState, metadata: PushSubscriptionMetadata) => {
  storage.set({metadata, state});
};

export const getStatus = () => storage.get();

export const clearStatus = () => storage.clear();
