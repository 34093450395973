import {PullNotificationEvent, PullNotificationEventAction} from '@mol-fe/mol-fe-web-push-types';
import {markAsTracked, wasTrackedBefore} from '../helpers/pullNotificationClickStorage';
import trackEvent from '../helpers/safeTrackEvent';
import {NotificationPullInitOpts} from '../types';

interface GetClickActionOpts {
  sharedCrossOrgUserId?: string | null;
  crossOrgUserId?: string;
  campaignId: string;
}

const getClickAction = ({
  sharedCrossOrgUserId,
  crossOrgUserId,
  campaignId
}: GetClickActionOpts): PullNotificationEventAction => {
  if (!sharedCrossOrgUserId) {
    return PullNotificationEventAction.CLICK_PAGE_LOAD;
  }

  if (crossOrgUserId !== sharedCrossOrgUserId) {
    return PullNotificationEventAction.CLICK_PAGE_SHARE;
  }

  if (wasTrackedBefore(campaignId, sharedCrossOrgUserId)) {
    return PullNotificationEventAction.CLICK_PAGE_REFRESH;
  }

  return PullNotificationEventAction.CLICK_PAGE_LOAD;
};

export const trackPullNotificationClickLoad = async (params: URLSearchParams, opts: NotificationPullInitOpts) => {
  const {crossOrgUserId, metadata, emit, baseEndpoint, apiKey} = opts;
  const campaignId = params.get('ci');
  const assetId = params.get('ai');
  const sharedCrossOrgUserId = params.get('xi');

  if (campaignId) {
    const action = getClickAction({
      campaignId,
      crossOrgUserId,
      sharedCrossOrgUserId
    });

    const notificationClickEvent: Omit<PullNotificationEvent, 'creationTimestamp' | 'epoch'> = {
      action,
      campaignId,
      crossOrgUserId,
      organisation: metadata.organisation,
      platform: metadata.platform,
      type: 'pull-notification',
      userAgent: navigator.userAgent
    };

    if (assetId) {
      notificationClickEvent.assetId = assetId;
    }

    await trackEvent(baseEndpoint, notificationClickEvent, apiKey);
    emit('pull_notification_click', notificationClickEvent);
    markAsTracked(campaignId, crossOrgUserId || 'unknown');
  }
};
