/* eslint-disable no-unused-expressions, no-bitwise, id-length */
const hasAtobBeenTampered = () => {
  try {
    // @ts-ignore
    window.atob();

    return true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return false;
  }
};

// Original code from https://gist.github.com/oeon/0ada0457194ebf70ec2428900ba76255
const decode = (encoded: string) => {
  let b: number;
  let c: number;
  let d: number;
  const char64Number: {[key: string]: number} = {};
  let f = 0;
  let g = 0;
  let decoded = '';
  const length = encoded.length;

  for (b = 0; b < 64; b++) {
    char64Number['ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.charAt(b)] = b;
  }

  for (c = 0; c < length; c++) {
    for (b = char64Number[encoded.charAt(c)], f = (f << 6) + b, g += 6; g >= 8; ) {
      ((d = 255 & (f >>> (g -= 8))) || length - 2 > c) && (decoded += String.fromCharCode(d));
    }
  }

  return decoded;
};

// Note: from https://github.com/web-push-libs/web-push#using-vapid-key-for-applicationserverkey
const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData: string = hasAtobBeenTampered() ? decode(base64) : window.atob(base64);

  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export default urlBase64ToUint8Array;
