import {ServiceWorkerSubscriptionReadyMessage, PromptEvent} from '@mol-fe/mol-fe-web-push-types';
import ms from 'ms';
import {CompleteRegisterOpts} from '../types';
import {requestPushNotificationPermission, subscribePushNotifications} from '../helpers/pushSubscription';
import {setStatus, clearStatus} from '../helpers/subscriptionStatus';
import trackEvent from '../helpers/safeTrackEvent';
import handleSubscriptionError from '../helpers/handleSubscriptionError';

export const LAST_PROMPT_EPOCH = 'mol-fe-browser-notifications-last-prompt-epoch';

const canShowPrompt = (promptFrequency?: unknown) => {
  if (!promptFrequency) {
    return true;
  }

  const frequency = ms(promptFrequency as string);

  if (typeof frequency !== 'number') {
    throw new TypeError('Prompt frequency must be a valid string like `1d` or `1h`');
  }

  if (!('localStorage' in window)) {
    return true;
  }

  const lastShown = JSON.parse(window.localStorage.getItem(LAST_PROMPT_EPOCH) || '0');

  return Date.now() - lastShown >= frequency;
};

export type RenderPrePrompt = (next: (accepted: boolean) => Promise<void>) => void;
export const firedPromptEventActions: string[] = [];

export default async (
  registration: ServiceWorkerRegistration,
  options: CompleteRegisterOpts & {renderPrePrompt?: RenderPrePrompt}
) => {
  const {
    apiKey,
    appServerKey,
    emit,
    metadata,
    baseEndpoint,
    subscriptionEndpoint,
    promptFrequency,
    rawAppServerKey,
    renderPrePrompt
  } = options;

  try {
    const {channels, crossOrgUserIds, platform, subChannels, userAgent, visitorIds} = metadata;
    const visitorId = Array.isArray(visitorIds) ? visitorIds[0] : undefined;
    const crossOrgUserId = Array.isArray(crossOrgUserIds) ? crossOrgUserIds[0] : undefined;
    const channel = Array.isArray(channels) ? channels[0] : undefined;
    const subChannel = Array.isArray(subChannels) ? subChannels[0] : undefined;
    const createPromptEvent = (
      data: Pick<PromptEvent, 'action' | 'permission'>
    ): Omit<PromptEvent, 'creationTimestamp' | 'epoch'> => {
      return {
        channel,
        crossOrgUserId,
        organisation: metadata.organisation,
        platform,
        subChannel,
        type: 'prompt',
        userAgent,
        visitorId,
        ...data
      };
    };

    const trackPromptEvent = async (data: Pick<PromptEvent, 'action' | 'permission'>) => {
      const {action} = data;
      const type = 'prompt';
      const promptEvent: Omit<PromptEvent, 'creationTimestamp' | 'epoch'> = createPromptEvent(data);

      firedPromptEventActions.push(action);
      emit(`${type}_${action}`, promptEvent);
      await trackEvent(baseEndpoint, promptEvent, apiKey);
    };

    if (!canShowPrompt(promptFrequency)) {
      emit(
        'prompt_discarded',
        createPromptEvent({
          // @ts-expect-error - we don't care about discarded action outside of emitting it.
          action: 'discarded',
          permission: Notification.permission,
          type: 'prompt'
        })
      );
      // We don't track discarded events because it was too noisy and expensive
    } else {
      if (Notification.permission === 'default') {
        const showNativePrompt = async () => {
          await trackPromptEvent({
            action: 'impression',
            permission: Notification.permission
          });

          const permission = await requestPushNotificationPermission();
          const promises: Promise<unknown>[] = [trackPromptEvent({action: 'click', permission})];

          if (permission === 'granted') {
            promises.push(
              subscribePushNotifications({
                apiKey,
                appServerKey,
                metadata,
                registration,
                subscriptionEndpoint
              })
            );
          }

          await Promise.all(promises);
        };

        if (typeof renderPrePrompt === 'function') {
          await trackPromptEvent({
            action: 'pre_prompt_impression',
            permission: Notification.permission
          });

          await new Promise((resolve) => {
            renderPrePrompt(async (accepted) => {
              await trackPromptEvent({
                action: 'pre_prompt_click',
                permission: accepted ? 'granted' : 'denied'
              });

              if (accepted) {
                await showNativePrompt();
              }

              resolve(accepted);
            });
          });
        } else {
          await showNativePrompt();
        }

        if ('localStorage' in window) {
          window.localStorage.setItem(LAST_PROMPT_EPOCH, JSON.stringify(Date.now()));
        }
      }

      if (Notification.permission === 'granted') {
        const subscription = (await registration.pushManager.getSubscription()) as PushSubscription;
        const msgData: ServiceWorkerSubscriptionReadyMessage = {
          subscription,
          type: 'web-push-sw-subscription-ready'
        };

        /// @ts-ignore
        registration.active.postMessage(JSON.stringify(msgData));

        setStatus('ready', metadata);
      } else if (Notification.permission === 'default') {
        setStatus('undecided', metadata);
      } else {
        clearStatus();
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    handleSubscriptionError(error as Error, {emit, metadata, rawAppServerKey});
  }
};
