import {SavedPullCampaign, PullSubscriptionMetadata} from '@mol-fe/mol-fe-web-push-types';
import {matchesSegment} from './matchesSegment';

export const matchesSegments = (
  metadata: PullSubscriptionMetadata,
  segments: SavedPullCampaign['segments']
): boolean => {
  const {exclude: excludeSegments, include: includeSegments} = segments;

  if (excludeSegments) {
    for (const segment of excludeSegments) {
      if (matchesSegment(metadata, segment)) {
        return false;
      }
    }
  }

  for (const segment of includeSegments) {
    if (matchesSegment(metadata, segment)) {
      return true;
    }
  }

  return false;
};
