import ms from 'ms';
import SimpleStorage from './SimpleStorage';

interface NotificationClickRecord {
  campaignId: string;
  crossOrgUserId: string;
  epoch: number;
}

export const storage = new SimpleStorage<NotificationClickRecord[]>('MOL-FE-WEB-PULL_NOTIFICATION_CLICKS');

export const markAsTracked = (campaignId: string, crossOrgUserId: string) => {
  const clickRecords = storage.get() || [];

  return storage.set([
    ...clickRecords,
    {
      campaignId,
      crossOrgUserId,
      epoch: Date.now()
    }
  ]);
};

export const wasTrackedBefore = (campaignId: string, crossOrgUserId: string): boolean => {
  const clickRecords = storage.get() || [];

  return clickRecords.some((record) => record.campaignId === campaignId && record.crossOrgUserId === crossOrgUserId);
};

export const clear = (): void => {
  return storage.clear();
};

export const getAll = (): NotificationClickRecord[] => {
  return storage.get() || [];
};

export const deleteOldClickRecords = (): void => {
  const clickRecords = storage.get() || [];

  storage.set(clickRecords.filter(({epoch}) => Date.now() - epoch < ms('24h')));
};
