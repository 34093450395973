import urlBase64ToUint8Array from './urlBase64ToUint8Array';
import fetch from './fetchWithRetry';

const fetchApplicationServerKey = async (serverKeyEndpoint: string, apiKey?: string) => {
  const requestInit: RequestInit = {
    mode: 'cors'
  };

  if (apiKey) {
    requestInit.headers = {Authorization: `ApiKey ${apiKey}`};
  }

  const response = await fetch(
    serverKeyEndpoint,
    requestInit,
    true,
    async (res) => (await res.clone().text()).trim() !== ''
  );
  const vapidPublicKey = await response.text();

  return {
    parsed: urlBase64ToUint8Array(vapidPublicKey),
    raw: vapidPublicKey
  };
};

export default fetchApplicationServerKey;
