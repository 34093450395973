import {PartialPick, PullSubscriptionMetadata, PushSubscriptionMetadata} from '@mol-fe/mol-fe-web-push-types';
import {serializeError, ErrorObject} from 'serialize-error';
import {CompleteRegisterOpts} from '../types';
import {addError} from './clientSubscriptionErrors';

export interface HandleRegisterOptions extends PartialPick<Omit<CompleteRegisterOpts, 'metadata'>, 'emit'> {
  metadata: PushSubscriptionMetadata | PullSubscriptionMetadata;
}

export const handleRegisterError = (error: Error, {emit, metadata, rawAppServerKey}: HandleRegisterOptions) => {
  const enhancedSerializedError = {
    ...serializeError(error),
    organisation: metadata?.organisation,
    rawAppServerKey,
    userAgent: navigator.userAgent
  } as unknown as ErrorObject;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (__DEBUG__) {
    addError(enhancedSerializedError);
  }
  emit('error', enhancedSerializedError);
};
