import {SavedPullSegment, PullSubscriptionMetadata} from '@mol-fe/mol-fe-web-push-types';
import {matchesAndCriteria} from './matchesAndCriteria';

export const matchesSegment = (metadata: PullSubscriptionMetadata, segment: SavedPullSegment) => {
  for (const criteria of segment.criteria.and) {
    if (!matchesAndCriteria(metadata, criteria)) {
      return false;
    }
  }

  return true;
};
