import {PullSubscriptionMetadata, SavedPullCampaign} from '@mol-fe/mol-fe-web-push-types';
import parseISOString from '../helpers/parseISOString';
import {fetchSegmentedPullCampaigns} from './fetchSegmentedPullCampaigns';

export const getLatestPullCampaign = async (
  baseEndpoint: string,
  meta: PullSubscriptionMetadata,
  apiKey?: string
): Promise<SavedPullCampaign> => {
  const campaigns = await fetchSegmentedPullCampaigns(baseEndpoint, meta, apiKey);

  return campaigns.sort((campaignA, campaignB) => {
    const epochA = parseISOString(campaignA.triggerTime || campaignA.creationTimestamp).getTime();
    const epochB = parseISOString(campaignB.triggerTime || campaignB.creationTimestamp).getTime();

    return epochB - epochA;
  })[0];
};
