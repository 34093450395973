import {PullSegmentCriterion, PullSubscriptionMetadata} from '@mol-fe/mol-fe-web-push-types';

const criterionProp2SubsMetaProp = (property: PullSegmentCriterion['property']): keyof PullSubscriptionMetadata => {
  switch (property) {
    case 'channel':
      return 'channels';
    case 'subchannel':
      return 'subChannels';
    case 'geo':
      return 'geo';
    case 'keyword':
      return 'keywords';
    case 'platform':
      return 'platform';
  }
};

const eqMatcher = (meta: PullSubscriptionMetadata, criterion: PullSegmentCriterion) => {
  const data = meta[criterionProp2SubsMetaProp(criterion.property)];

  if (!data) {
    return criterion.value === 'ALL';
  }

  const values = Array.isArray(data) ? data : [data];

  return values.some((value) => value === criterion.value);
};

const oneOfMatcher = (meta: PullSubscriptionMetadata, criterion: PullSegmentCriterion) => {
  const data = meta[criterionProp2SubsMetaProp(criterion.property)];

  if (!data) {
    return false;
  }

  const values = Array.isArray(data) ? data : [data];
  const criterionValues = Array.isArray(criterion.value) ? criterion.value : [criterion.value];

  return values.some((value) => criterionValues.includes(value));
};

const criterionMatchers: {[key: string]: (meta: PullSubscriptionMetadata, criterion: PullSegmentCriterion) => boolean} =
  {
    eq: eqMatcher,
    neq: (meta: PullSubscriptionMetadata, criterion: PullSegmentCriterion) => !eqMatcher(meta, criterion),
    not_one_of: (meta: PullSubscriptionMetadata, criterion: PullSegmentCriterion) => !oneOfMatcher(meta, criterion),
    one_of: oneOfMatcher
  };

export const matchesCriterion = (meta: PullSubscriptionMetadata, criterion: PullSegmentCriterion) => {
  const matcher = criterionMatchers[criterion.operator];

  return matcher(meta, criterion);
};
