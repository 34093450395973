import {AnalyticsEvent} from '@mol-fe/mol-fe-web-push-types';
import trackEvent from './trackEvent';

const safeTrackEvent = async (
  baseEndpoint: string,
  event: Omit<AnalyticsEvent, 'creationTimestamp' | 'epoch'>,
  apiKey?: string
) => {
  try {
    await trackEvent(baseEndpoint, event, apiKey);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error('TrackEvent Error', error);
  }
};

export default safeTrackEvent;
