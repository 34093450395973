export default (environment: string) => {
  switch (environment) {
    case 'production':
      return 'https://hulkprod.anm.co.uk/api/web-push-notification';
    case 'integration':
      return 'https://hulkint.anm.co.uk/api/web-push-notification';
    default:
      return '/public';
  }
};
