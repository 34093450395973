/* eslint-disable @typescript-eslint/no-explicit-any */
type Listener = (...args: any[]) => void;

interface Events {
  [key: string]: Listener[] | null;
}

interface Logger {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message?: any, ...optionalParams: any[]): void;
}

/**
 * @alias module:tiny-emitter
 * @class
 * @description Subset of  node's [Emitter class]{@link https://nodejs.org/api/events.html#events_class_eventemitter}
 * @param logger - Optional logger instance. Must comply to the [Console interface]{@link https://developer.mozilla.org/es/docs/Web/API/Console}.
 */
class Emitter<EventTypes extends string = string> {
  logger: Logger;
  events: Events;

  constructor(logger?: Logger) {
    this.events = {};
    this.logger = logger || console;
  }

  /**a
   * Adds the listener function to the end of the listeners array for the event named eventName.
   *
   * @param eventName - The name of the event.
   * @param listener - Listener fn that handles the evt.
   * @returns - The Emitter instance.
   */
  on(eventName: EventTypes, listener: Listener) {
    const events = this.events;
    const evtListeners = events[eventName] || (events[eventName] = []);

    evtListeners.push(listener);

    return this;
  }

  /**
   * Removes the specified listener from the listener array for the event named eventName.
   *
   * @param eventName - The name of the event.
   * @param listener - Listener fn that handles the evt.
   * @returns - The Emitter instance.
   */
  removeListener(eventName: EventTypes, listener: Listener) {
    const events = this.events;
    const evtListeners = events[eventName] || (events[eventName] = []);

    events[eventName] = evtListeners.filter((eListener: Listener) => eListener !== listener);

    return this;
  }

  /**
   * Removes all listeners, or those of the specified eventName.
   *
   * @param eventName - The name of the event. Optional if ommited all listeners will be removed.
   * @returns - The Emitter instance.
   */
  removeAllListeners(eventName?: EventTypes) {
    if (eventName) {
      this.events[eventName] = null;
    } else {
      this.events = {};
    }

    return this;
  }

  /**
   * Synchronously calls each of the listeners registered for the event named eventName, in the order they were registered,
   * passing the supplied arguments to each.
   *
   * @param eventName - The name of the event.
   * @returns - Returns true if the event had listeners, false otherwise.
   */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  emit(eventName: EventTypes, ...args: any[]) {
    const events = this.events;
    const evtListeners = events[eventName] || (events[eventName] = []);
    const haveListeners = evtListeners.length > 0;
    let clonedArgs = args;

    // due to unexpected mutations on the event data (rta), we clone the data to prevent errors in ES
    try {
      clonedArgs = JSON.parse(JSON.stringify(args));
    } catch (err) {
      // noop
    }

    evtListeners.forEach((handler: Listener) => {
      try {
        handler(...clonedArgs);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        this.logger.error(error, (error as Error).stack);
      }
    });

    return haveListeners;
  }
}

export default Emitter;
