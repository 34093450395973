import {getISOStringParts} from './getISOStringParts';

const parseISOString = (stringDate: string) => {
  const [year, month, date, hours, minutes, seconds, ms] = getISOStringParts(stringDate).map((digit) =>
    parseInt(digit, 10)
  );

  return new Date(Date.UTC(year, month - 1, date, hours, minutes, seconds, ms || 0));
};

export default parseISOString;
