import ms from 'ms';
import documentReady from '../helpers/documentReady';
import {clearIntervalAsync, setIntervalAsync, SetIntervalAsyncTimer} from '../helpers/setAsyncInterval';
import {NotificationPullInitOpts} from '../types';
import {handleRegisterError} from '../helpers/handleRegisterError';
import {deleteOldClickRecords} from '../helpers/pullNotificationClickStorage';
import {pullNotifierFactory} from './pullNotifierFactory';
import {trackPullNotificationClickLoad} from './trackPullNotificationClickLoad';

export const init = async (opts: NotificationPullInitOpts) => {
  const {showPullNotification} = pullNotifierFactory(opts);
  let timer: SetIntervalAsyncTimer<[]> | null = null;

  const startPullNotifications = async () => {
    await showPullNotification();
    timer = setIntervalAsync(showPullNotification, ms('30s'));
  };

  const stopPullNotifications = async () => {
    if (timer) {
      await clearIntervalAsync(timer);
      timer = null;
    }
  };

  const onVisibilitychange = async () => {
    if (document.visibilityState === 'hidden') {
      await stopPullNotifications();
    }

    if (document.visibilityState === 'visible' && !timer) {
      await startPullNotifications();
    }
  };

  const cleanup = async () => {
    document.removeEventListener('visibilitychange', onVisibilitychange);
    await stopPullNotifications();
  };

  try {
    await documentReady;
    deleteOldClickRecords();
    const params = new URLSearchParams(window.location.search);
    const ito = params.get('ito');

    if (ito === 'pull-notification') {
      await trackPullNotificationClickLoad(params, opts);
    }

    if (document.visibilityState === 'visible') {
      await startPullNotifications();
    }

    document.addEventListener('visibilitychange', onVisibilitychange);
  } catch (error: unknown) {
    handleRegisterError(error as Error, {emit: opts.emit, metadata: opts.metadata});
  }

  return {
    cleanup
  };
};
