import ms from 'ms';
import SimpleStorage from './SimpleStorage';

export interface HashStorageEntry<T> {
  data: T;
  ttl: number;
  epoch: number;
}

interface StorageHash<T> {
  [key: string]: HashStorageEntry<T>;
}

export class HashStorage<T> {
  readonly name: string;
  readonly storage: SimpleStorage<{[key: string]: HashStorageEntry<T>}>;
  readonly defaultTTL: number;
  readonly entriesLimit: number;

  constructor(name: string, ttl = ms('24h'), entriesLimit = 1000) {
    this.name = name;
    this.storage = new SimpleStorage(name);
    this.defaultTTL = ttl;
    this.entriesLimit = entriesLimit;
    this.syncHash();
  }

  private getHash() {
    return this.storage.get() || {};
  }

  private syncHash() {
    const hash = this.getHash();
    const syncHash: StorageHash<T> = {};
    const recentEntriesFirst = Object.entries(hash).sort((entryA, entryB) => {
      return entryB[1].epoch - entryA[1].epoch;
    });
    let totalEntries = 0;

    for (const [key, entry] of recentEntriesFirst) {
      if (Date.now() - entry.epoch < entry.ttl && totalEntries < this.entriesLimit) {
        syncHash[key] = entry;
        totalEntries += 1;
      }
    }

    this.storage.set(syncHash);
  }

  public get(key: string) {
    const entry = this.getHash()[key];

    if (entry) {
      return entry.data;
    }

    return null;
  }

  public set(key: string, data: T, ttl: number) {
    const hash = this.getHash();
    const entry: HashStorageEntry<T> = {
      data,
      epoch: Date.now(),
      ttl
    };

    hash[key] = entry;
    this.storage.set(hash);
    this.syncHash();
  }

  public clear() {
    this.storage.clear();
  }
}
