import {PullSubscriptionMetadata, SavedPullCampaign} from '@mol-fe/mol-fe-web-push-types';
import {PullNotificationData} from '../types';

export const getPullNotificationData = (
  metadata: PullSubscriptionMetadata,
  campaign: SavedPullCampaign,
  crossOrgUserId?: string
): PullNotificationData => {
  const {assetId, body, clickThroughUrl, id, organisation, title, image, icon, metadata: campaignMeta} = campaign;
  const {geo, platform} = metadata;

  const notificationClickUrl = new URL(clickThroughUrl);

  if (!notificationClickUrl.searchParams.has('ito')) {
    notificationClickUrl.searchParams.append('ito', 'pull-notification');
  }

  notificationClickUrl.searchParams.append('ci', `${campaign.id}`);

  if (crossOrgUserId) {
    notificationClickUrl.searchParams.append('xi', `${crossOrgUserId}`);
  }

  if (assetId) {
    notificationClickUrl.searchParams.append('ai', `${assetId}`);
  }

  const notificationData: PullNotificationData = {
    assetId,
    body,
    campaignId: id,
    clickThroughUrl: notificationClickUrl.href,
    geo,
    icon,
    image,
    metadata: campaignMeta,
    organisation,
    platform,
    title,
    type: 'pull-notification',
    userAgent: navigator.userAgent
  };

  return notificationData;
};
