import {PushSubscriptionClientSideError} from '@mol-fe/mol-fe-web-push-types';
import {serializeError, ErrorObject} from 'serialize-error';
import urlJoin from 'url-join';
import SimpleStorage from './SimpleStorage';
import fetch from './fetchWithRetry';

export type ClientSubscriptionErrors = PushSubscriptionClientSideError[];

const storageKey = 'MOL-FE-WEB-PUSH_CLIENT_SUBSCRIPTION_ERRORS';

const sessionStore = new SimpleStorage<ClientSubscriptionErrors>(storageKey, sessionStorage);

let baseEndpoint: string;

export const setBaseEndpoint = (endpoint: string) => (baseEndpoint = endpoint);

export const getErrors = () => sessionStore.get();

export const addError = (err: Error | ErrorObject) => {
  const error = serializeError(err);
  const session = getErrors();
  // a bit crude and not 100% efficient
  const isDuplicate = session?.find((storedError) => JSON.stringify(storedError) === JSON.stringify(error));

  if (!isDuplicate) {
    sessionStore.set([...(session || []), error]);
  }
};

export const clear = () => sessionStore.clear();

export const trackClientErrors = async (authorizationHeader?: string) => {
  const errors = getErrors();

  if (!errors || errors.length === 0) {
    return;
  }

  try {
    const trackEndpoint = urlJoin(baseEndpoint, 'analytics/client/error');
    const requestInit: RequestInit = {
      body: JSON.stringify({errors}),
      headers: {
        'Content-type': 'application/json'
      },
      method: 'post'
    };

    if (authorizationHeader) {
      requestInit.headers = {...requestInit.headers, Authorization: authorizationHeader};
    }

    const response = await fetch(trackEndpoint, requestInit, false);

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    clear();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // noop for now we do not care if it fails
    // eslint-disable-next-line no-console
    console.error('Track Client Errors', error);
  }
};
