import {SetIntervalAsyncHandler} from './types';

declare type NativeTimeout = unknown;

declare function setTimeout(handler: (...args: unknown[]) => void, delayMs: number, ...args: unknown[]): NativeTimeout;

declare function clearTimeout(timeout: NativeTimeout): void;

const MIN_INTERVAL_MS = 10;
const MAX_INTERVAL_MS = 2147483647;

export class SetIntervalAsyncTimer<HandlerArgs extends unknown[]> {
  private timeout: NativeTimeout | undefined = undefined;
  private promise: Promise<void> | undefined = undefined;
  private stopped = false;

  static startTimer<HandlerArgs extends unknown[]>(
    handler: SetIntervalAsyncHandler<HandlerArgs>,
    intervalMs: number,
    ...handlerArgs: HandlerArgs
  ): SetIntervalAsyncTimer<HandlerArgs> {
    const sanitisedIntervalMs = Math.min(Math.max(Math.trunc(intervalMs), MIN_INTERVAL_MS), MAX_INTERVAL_MS);
    const timer = new SetIntervalAsyncTimer<HandlerArgs>();

    timer.scheduleTimeout(handler, sanitisedIntervalMs, sanitisedIntervalMs, ...handlerArgs);

    return timer;
  }

  static async stopTimer<HandlerArgs extends unknown[]>(timer: SetIntervalAsyncTimer<HandlerArgs>): Promise<void> {
    timer.stopped = true;

    if (timer.timeout) {
      clearTimeout(timer.timeout);
    }

    if (timer.promise) {
      await timer.promise;
    }
  }

  scheduleTimeout(
    handler: SetIntervalAsyncHandler<HandlerArgs>,
    intervalMs: number,
    delayMs: number,
    ...handlerArgs: HandlerArgs
  ): void {
    this.timeout = setTimeout(async () => {
      this.timeout = undefined;
      this.promise = this.runHandlerAndScheduleTimeout(handler, intervalMs, ...handlerArgs);

      await this.promise;

      this.promise = undefined;
    }, delayMs);
  }

  async runHandlerAndScheduleTimeout(
    handler: SetIntervalAsyncHandler<HandlerArgs>,
    intervalMs: number,
    ...handlerArgs: HandlerArgs
  ): Promise<void> {
    try {
      await handler(...handlerArgs);
    } catch (_: unknown) {
      // DO nothing
    } finally {
      if (!this.stopped) {
        this.scheduleTimeout(handler, intervalMs, intervalMs, ...handlerArgs);
      }
    }
  }
}
