import {PullCampaignType, PullSubscriptionMetadata, SavedPullCampaign} from '@mol-fe/mol-fe-web-push-types';
import parseISOString from '../helpers/parseISOString';

export const isActive = (platform: PullSubscriptionMetadata['platform'], pullCampaign: SavedPullCampaign) => {
  if (!pullCampaign.ttl) {
    return false;
  }

  const ttl = platform === 'desktop' ? pullCampaign.ttl.desktop : pullCampaign.ttl.mobile;
  const ttlInMs = ttl * 1000;
  const startISOString =
    pullCampaign.type === PullCampaignType.SCHEDULED
      ? (pullCampaign.triggerTime as string)
      : pullCampaign.creationTimestamp;
  const startTime = parseISOString(startISOString).getTime();
  const expireTime = new Date(startTime + ttlInMs).getTime();

  return Date.now() <= expireTime;
};
