import {PullNotificationEvent, PullNotificationEventAction} from '@mol-fe/mol-fe-web-push-types';
import ms from 'ms';
import {HashStorage} from '../helpers/hashStorage';
import {PullNotificationData, NotificationPullInitOpts} from '../types';
import trackEvent from '../helpers/safeTrackEvent';
import {getLatestPullCampaign} from './getLatestPullCampaign';
import {getPullNotificationData} from './getPullNotificationData';

const pullNotificationEventHandler =
  (
    action: PullNotificationEventAction.CLICK | PullNotificationEventAction.IMPRESSION,
    emit: NotificationPullInitOpts['emit'],
    baseEndpoint: string,
    apiKey: string | undefined,
    data: PullNotificationData
  ) =>
  async () => {
    const event: Omit<PullNotificationEvent, 'creationTimestamp' | 'epoch'> = {
      action,
      campaignId: data.campaignId,
      crossOrgUserId: data.crossOrgUserId,
      organisation: data.organisation,
      platform: data.platform,
      type: 'pull-notification',
      userAgent: navigator.userAgent
    };

    if (action === PullNotificationEventAction.CLICK) {
      await trackEvent(baseEndpoint, event, apiKey);
    }

    emit(`pull_notification_${action}`, event);
  };

export const pullNotifierFactory = (opts: NotificationPullInitOpts) => {
  const {apiKey, baseEndpoint, metadata, crossOrgUserId, renderPullNotification, emit, canRender = () => true} = opts;
  const storage: HashStorage<boolean> = new HashStorage('hulk_pull-notification_shown_campaigns', ms('24h'), 1000);

  const showPullNotification = async () => {
    const campaign = await getLatestPullCampaign(baseEndpoint, metadata, apiKey);

    if (campaign && !storage.get(campaign.id) && document.visibilityState === 'visible' && canRender(campaign)) {
      const notificationData = getPullNotificationData(metadata, campaign, crossOrgUserId);
      const onImpression = pullNotificationEventHandler(
        PullNotificationEventAction.IMPRESSION,
        emit,
        baseEndpoint,
        apiKey,
        notificationData
      );
      const onClick = pullNotificationEventHandler(
        PullNotificationEventAction.CLICK,
        emit,
        baseEndpoint,
        apiKey,
        notificationData
      );

      await renderPullNotification(notificationData, {onClick});
      await onImpression();
      storage.set(campaign.id, true, ms('24h'));
    }
  };

  return {showPullNotification, storage};
};
