const FRAME_TIMEOUT = 10000;

export const loadIframeAndWaitForMessage = async <T>(frameUrl: string, expectedScope: string) => {
  const expectedOrigin = new URL(frameUrl).origin;

  return new Promise<T | null>((resolve, reject) => {
    // eslint-disable-next-line prefer-const
    let timeout: number | undefined;
    let iframe: HTMLIFrameElement | null = null;
    const messageHandler = (event: MessageEvent) => {
      if (event.data.scope !== expectedScope || event.origin !== expectedOrigin) {
        return;
      }

      window.removeEventListener('message', messageHandler);
      if (timeout) {
        clearTimeout(timeout);
      }

      if (typeof event.data.data !== 'undefined') {
        resolve(event.data.data);
      } else {
        reject(new Error('Invalid data posted back from crossOrg iframe'));
      }

      if (iframe) {
        iframe.remove();
      }
    };

    window.addEventListener('message', messageHandler);

    iframe = document.createElement('iframe');

    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.border = 'none';
    iframe.src = frameUrl;
    document.body.appendChild(iframe);

    timeout = window.setTimeout(() => {
      window.removeEventListener('message', messageHandler);
      reject(new Error('crossOrg iframe timed out'));

      if (iframe) {
        iframe.remove();
      }
    }, FRAME_TIMEOUT);
  });
};
