import {PullSubscriptionMetadata, SavedPullCampaign} from '@mol-fe/mol-fe-web-push-types';
import {fetchPullCampaigns} from './fetchPullCampaigns';
import {isActive} from './isActive';
import {matchesSegments} from './matchesSegments';

export const fetchSegmentedPullCampaigns = async (
  baseEndpoint: string,
  meta: PullSubscriptionMetadata,
  apiKey?: string
): Promise<SavedPullCampaign[]> => {
  const campaigns = await fetchPullCampaigns(baseEndpoint, apiKey);

  return campaigns.filter((campaign) => isActive(meta.platform, campaign) && matchesSegments(meta, campaign.segments));
};
