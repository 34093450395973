import {PushSubscriptionMetadata} from '@mol-fe/mol-fe-web-push-types';
import {RegisterEmit} from '../register';
import {getCrossOrgUserIdFromCentral} from './crossOrgRequest';
import {handleRegisterError} from './handleRegisterError';
import {getStatus} from './subscriptionStatus';

export const getCrossOrgUserId = async (metadata: PushSubscriptionMetadata, emit: RegisterEmit) => {
  const subscriptionStatus = getStatus();
  const {organisation} = metadata;
  const crossOrgUserIds =
    subscriptionStatus && Array.isArray(subscriptionStatus.metadata.crossOrgUserIds)
      ? subscriptionStatus.metadata.crossOrgUserIds
      : [];

  try {
    const crossOrgUserId = (await getCrossOrgUserIdFromCentral(organisation)) || undefined;

    return crossOrgUserId;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    handleRegisterError(error as Error, {emit, metadata});
  }

  return crossOrgUserIds[crossOrgUserIds.length - 1];
};
