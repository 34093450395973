import {loadIframeAndWaitForMessage} from './crossFrame';

export const API_ROOT_BY_ENV: {[env: string]: {[org: string]: string}} = {
  development: {
    central: `${location.protocol}//${location.host}/public/v1/static/latest/mol-fe-web-push-browser-register`
  },
  integration: {
    central: 'https://hulkint.anm.co.uk/api/web-push-notification/v1/static/latest/mol-fe-web-push-browser-register'
  },
  production: {
    central: 'https://hulkprod.anm.co.uk/api/web-push-notification/v1/static/latest/mol-fe-web-push-browser-register'
  }
};

const PROD_HOSTNAMES: {[key: string]: string[]} = {
  inews: ['inews.co.uk'],
  metro: ['metro.co.uk'],
  mol: ['www.dailymail.co.uk', 'a.dailymail.co.uk', 'b.dailymail.co.uk', 'c.dailymail.co.uk'],
  newzit: ['www.newzit.com']
};

const getEnvironmentByOrganisationAndUrl = (organisation: string, url: string) => {
  const {hostname} = new URL(url);

  if (hostname === 'localhost' || hostname.endsWith('ngrok.io')) {
    return 'development';
  }

  if (!PROD_HOSTNAMES[organisation]) {
    throw new Error(`No hostnames for organisation: ${organisation}`);
  }

  if (PROD_HOSTNAMES[organisation].includes(hostname)) {
    return 'production';
  }

  return 'integration';
};

export const getCrossOrgUserIdFromCentral = async (organisation: string) => {
  const environment = getEnvironmentByOrganisationAndUrl(organisation, location.href);
  const frameUrl = `${API_ROOT_BY_ENV[environment].central}/crossOrgInlinedMin.html?command=getCrossOrgUserId`;
  const data = await loadIframeAndWaitForMessage<{crossOrgUserId: string}>(
    frameUrl,
    'mol-fe-web-push-cross-org-user-id'
  );

  return data && data.crossOrgUserId;
};
