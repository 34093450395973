export type PushSubscriptionErrorType = 'register' | 'update';

class PushSubscriptionError extends Error {
  type: PushSubscriptionErrorType;
  cause?: Error;

  constructor(message: string, type: PushSubscriptionErrorType, cause?: Error) {
    super(message);

    // Check https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // to understand the need to set the prototype for PushSubscriptionError Class
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, PushSubscriptionError.prototype);
    } else {
      /// @ts-ignore
      this.__proto__ = PushSubscriptionError.prototype;
    }

    this.type = type;
    this.cause = cause;
  }
}

export default PushSubscriptionError;
