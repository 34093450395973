/* eslint-disable id-length */
const equalUint8Array = (a: Uint8Array, b: Uint8Array) => {
  if (a.byteLength !== b.byteLength) {
    return false;
  }

  for (let i = 0; i !== a.byteLength; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
};

export default equalUint8Array;
