import {CompleteRegisterOpts} from '../types';
import PushSubscriptionError from './PushSubscriptionError';
import {setStatus} from './subscriptionStatus';
import {handleRegisterError} from './handleRegisterError';

export type HandleSubscriptionOptions = Pick<CompleteRegisterOpts, 'rawAppServerKey' | 'emit' | 'metadata'>;

export default (error: Error, opts: HandleSubscriptionOptions) => {
  if (error instanceof PushSubscriptionError) {
    const {metadata} = opts;

    if (error.type === 'register') {
      setStatus('register_pending', metadata);
    } else if (error.type === 'update') {
      setStatus('update_pending', metadata);
    }
  }

  handleRegisterError(error, opts);
};
