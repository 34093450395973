// @ts-ignore
import pRetry from 'p-retry';
import {OperationOptions as RetryOptions} from 'retry';
import debounce from 'debounce-promise';
import {trackClientErrors} from './clientSubscriptionErrors';

type FetchFn = (
  input: RequestInfo,
  init?: RequestInit,
  withClientError?: boolean,
  responseValidator?: (res: Response) => Promise<boolean>
) => Promise<Response>;

export class FetchError extends Error {
  readonly url: string;
  readonly method: string;
  readonly requestBody?: string;
  readonly responseStatus?: number;

  constructor(message: string, url: string, method: string, requestBody?: string, responseStatus?: number) {
    super(message);

    // Check https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // to understand the need to set the prototype for PushSubscriptionError Class
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, FetchError.prototype);
    } else {
      /// @ts-ignore
      this.__proto__ = FetchError.prototype;
    }
    this.url = url;
    this.method = method;
    this.requestBody = requestBody;
    this.responseStatus = responseStatus;
  }
}

const getContentTypeHeader = (headers: HeadersInit): string | null => {
  for (const header of Object.keys(headers)) {
    if (header.toLowerCase() === 'content-type') {
      /// @ts-ignore
      return headers[header];
    }
  }

  return null;
};

export const fRetry =
  (retryOptions: RetryOptions): FetchFn =>
  async (input, init, withClientError = true, responseValidator): Promise<Response> => {
    const debounceTrackClientErrors = debounce(trackClientErrors, 500);

    const run = async (numberOfAttempts: number) => {
      if (init && init.method && init.method.toUpperCase() !== 'GET' && init.headers && typeof init.body === 'string') {
        const contentType = getContentTypeHeader(init.headers);

        if (contentType && contentType.toLowerCase() === 'application/json') {
          const requestBody = JSON.parse(init.body);

          if (typeof requestBody === 'object') {
            init.body = JSON.stringify({
              __numberOfAttempts: numberOfAttempts,
              ...requestBody
            });
          }
        }
      }

      const url = typeof input === 'string' ? input : input.url;
      const method = (init && init.method) || (typeof input !== 'string' && input.method) || 'get';
      const body =
        JSON.parse(((init && init.body) || (typeof input !== 'string' && (await input.clone().text()))).toString()) ||
        undefined;

      try {
        const response = await fetch(input, {...init, credentials: init?.credentials || 'omit'});
        const isResponseInvalid = responseValidator && !(await responseValidator(response));

        if (!response.ok || isResponseInvalid) {
          const message = isResponseInvalid ? 'Failed response validation' : response.statusText;

          throw new FetchError(message, url, method, body, response.status);
        }

        return response;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error instanceof FetchError) {
          throw error;
        }

        throw new FetchError((error as Error).message || 'Unexpected fetch error', url, method, body);
      } finally {
        if (withClientError) {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          debounceTrackClientErrors(init?.headers?.Authorization);
        }
      }
    };

    return pRetry(run, retryOptions);
  };

const fetchWithRetry = fRetry({retries: 5});

export default fetchWithRetry;
